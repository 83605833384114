import React from "react"

const Header = () => (
  <nav className="navbar" role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
      <a className="navbar-item has-text-weight-bold	" href="/">
        Accueil
      </a>
      <a className="navbar-item has-text-weight-bold	" href="/cgv">
        CGV
    </a>
    </div>

  </nav>
)


export default Header
