import React from 'react';
import Layout from "../components/layout.js"
const custoPage = ({ data }) => {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark

    return (
        <Layout>
            <section className="hero">
                <div className="hero-body">
                    <div className="container">
                        <h3 className="title has-text-centered	">{frontmatter.title}</h3>
                    </div>
                </div>
            </section>
            <section className="hero">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-8 ">
                                <div
                                    className="blog-post-content"
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default custoPage;


export const custoQuery = graphql`
  query CustoPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
        frontmatter {
            title
            templateKey
            slug
        }
        html
    }
  }
`